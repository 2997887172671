<template>
  <v-container fluid>
    <v-card class="pb-4">
      <v-card-title primary-title>
        <div>
          <div class="headline" style="word-break: break-word"
            >Assista aos vídeos explicativos e veja tudo o que o Simplificador
            tem a oferecer</div
          >
        </div>
      </v-card-title>
      <v-data-iterator
        :items="items"
        :page.sync="page"
        :items-per-page.sync="options.itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
      <template v-slot:default="props">
        <v-row>
          <v-col 
            v-for="item in props.items"
            :key="item.url"
            cols="12"
            sm="6"
            md="3"
          >
            <v-card height="100%">
              <v-divider></v-divider>
              <v-list dense>
                <youtube
                  :video-id="getId(item.url)"
                  width="100%"
                  height="100%"
                  ref="youtube"
                  :player-vars="playerVars"
                ></youtube>
              </v-list>

              <p class="text-center subheading font-weight-medium pb-2">
                {{item.name}}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </template>  
      </v-data-iterator>
      <div class="text-center pt-10">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    step: 1,
    page:1,
    pageCount: 0,
    options: {
      itemsPerPage: 4,
    },
    playerVars: {
      showinfo: 0,
      rel: 0,
    },
    items: [
      {
        name: 'Tutorial completo sobre o Simplificador',
        url: 'https://youtube.com/watch?v=9C4sTCH_hOw',
      },
      {
        name: 'Tutorial da Tela Inicial',
        url: 'https://youtube.com/watch?v=cmxAo6dwiEo',
      },
      {
        name: 'Tutorial da página "Impostos"',
        url: 'https://youtube.com/watch?v=wNN7R6cVEa0',
      },
      {
        name: 'Tutorial da página "Sua Empresa"',
        url: 'https://youtube.com/watch?v=5YLqIR4JGPQ',
      },
      {
        name: 'Tutorial da página "Sua Conta"',
        url: 'https://youtube.com/watch?v=TMhlDVdl630',
      },
      {
        name: 'Tutorial da página "Despesas"',
        url: 'https://youtube.com/watch?v=QHT-lfld6iA',
      },
      {
        name: 'Tutorial da página "Serviços Adicionais"',
        url: 'https://youtube.com/watch?v=cgA72e8XMdY',
      },
    ],
  }),
  methods: {
    getId(url) {
      return this.$youtube.getIdFromUrl(url)
    },
  },
}
</script>

<style scoped>

</style>
