<template>
  <v-container fluid>
    <v-row >
      <v-subheader class="px-0">Central de Ajuda</v-subheader>
      <v-card width="100%">
        <help-center-header></help-center-header>
        <system-video></system-video>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import HelpCenterHeader from '@src/components/helpcenter/HelpCenterHeader.vue'
import SystemVideo from '@src/components/helpcenter/SystemVideo.vue'

export default {
  name: 'index-help-center',
  computed: {},
  components: {
    HelpCenterHeader,
    SystemVideo,
  },
  data: () => ({}),
  methods: {
    ...mapActions({}),
  },
  mounted() {},
}
</script>
<style scoped lang="sass"></style>
