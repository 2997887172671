<template>
  <div class="socials">
    <a href="https://www.facebook.com/Simplificador-137976776906058">
      <v-avatar :size="socialSize" class="facebook">
        <img :src="facebook" />
      </v-avatar>
    </a>
    <a href="https://www.linkedin.com/company/simplificador">
      <v-avatar :size="socialSize" class="linkedin mx-1">
        <img :src="linkedin" />
      </v-avatar>
    </a>
    <a href="https://www.youtube.com/channel/UCtBHn18YqUG8qi8BWt1bKsQ">
      <v-avatar :size="socialSize" class="youtube">
        <img :src="youtube" />
      </v-avatar>
    </a>
  </div>
</template>
<script>
import facebook from '@src/assets/images/icons/social/facebook.svg'
import linkedin from '@src/assets/images/icons/social/linkedin.svg'
import twitter from '@src/assets/images/icons/social/twitter.svg'
import youtube from '@src/assets/images/icons/social/youtube.svg'

export default {
  data() {
    return {
      facebook,
      linkedin,
      twitter,
      youtube,
    }
  },
  props: {
    socialSize: {
      type: String,
      default: '24',
    },
  },
  methods: {
    mailto() {
      window.location.href = 'mailto:contato@simplificador.com.br'
    },
  },
}
</script>
<style lang="sass" scoped>
.socials
    margin-top: 3px
    display: inline

    img
        height: 20px

    .facebook
        background-color: #3b5999

    .linkedin
        background-color: #0077B5

    .youtube
        background-color: #cd201f
</style>
