<template>
  <base-header-information :title="headerTitle" >
    <template slot="description">
      <span>
        Aqui você encontra os vídeos tutoriais que explicam o passo a passo de
        cada área do sistema. Sempre que tiver alguma dúvida, é só vir até aqui!
        É claro, se preferir, pode contar com a ajuda da nossa equipe pelo chat,
        e-mail atendimento@simplificador.com.br ou pelo telefone (11) 4130-7932.
      </span>
      <span>
        Lembrando que nosso horário de atendimento é de segunda a sexta-feira,
        das 10h às 12h e das 13h às 17h.
      </span>
    </template>
  </base-header-information>
</template>

<script>

import SocialMedias from '@src/components/widget/SocialMedias.vue'

export default {
  components: {
    SocialMedias,
  },
  data() {
    return {
      headerTitle: 'Bem-vindo a central de ajuda do Simplificador',
      socials: [
        {
          icon: 'fab fa-facebook',
          color: 'indigo',
        },
        {
          icon: 'fab fa-linkedin',
          color: 'cyan darken-1',
        },
        {
          icon: 'fab fa-instagram',
          color: 'red lighten-3',
        },
      ],
    }
  },
}
</script>

<style scoped></style>
